import { useTranslation } from '@pancakeswap/localization'
import { Currency, CurrencyAmount, Percent, TradeType } from '@pancakeswap/sdk'
import { Pair } from '@pancakeswap/smart-router/evm'
import { Modal, ModalV2, QuestionHelper, SearchIcon, Text, Flex, AutoColumn } from '@pancakeswap/uikit'

import { RowBetween } from 'components/Layout/Row'
import { BUYBACK_FEE, LP_HOLDERS_FEE, TOTAL_FEE, TREASURY_FEE } from 'config/constants/info'
import { useState } from 'react'
import { ONE_BIPS } from 'config/constants/exchange'
import { RouterViewer } from './RouterViewer'
import SwapRoute from './SwapRoute'

function TradeSummary({
  inputAmount,
  outputAmount,
  tradeType,
  slippageAdjustedAmounts,
  priceImpactWithoutFee,
  realizedLPFee,
  hasStablePair = false,
  isMM = false,
}: {
  hasStablePair?: boolean
  inputAmount?: CurrencyAmount<Currency>
  outputAmount?: CurrencyAmount<Currency>
  tradeType?: TradeType
  slippageAdjustedAmounts: {
    INPUT?: CurrencyAmount<Currency>
    OUTPUT?: CurrencyAmount<Currency>
  }
  priceImpactWithoutFee?: Percent
  realizedLPFee?: CurrencyAmount<Currency>
  isMM?: boolean
}) {
  const { t } = useTranslation()
  const isExactIn = tradeType === TradeType.EXACT_INPUT
  const totalFeePercent = `${(TOTAL_FEE * 100).toFixed(2)}%`
  const lpHoldersFeePercent = `${(LP_HOLDERS_FEE * 100).toFixed(2)}%`
  const treasuryFeePercent = `${(TREASURY_FEE * 100).toFixed(4)}%`
  const buyBackFeePercent = `${(BUYBACK_FEE * 100).toFixed(4)}%`

  return (
    <div className="flex flex-col gap-1">
      {priceImpactWithoutFee && (
        <div className="flex flex-row items-center justify-between">
          <p className="text-xs text-gray-6">Max Slippage</p>
          <p className="text-xs text-gray-6">
            {isMM
              ? '--'
              : priceImpactWithoutFee
              ? priceImpactWithoutFee.lessThan(ONE_BIPS)
                ? '<0.01%'
                : `${priceImpactWithoutFee.toFixed(2)}%`
              : '-'}
          </p>
        </div>
      )}

      {realizedLPFee && (
        <div className="flex flex-row items-center justify-between">
          <p className="text-xs text-gray-6">Service Fee</p>
          <p className="text-xs text-gray-6">{`${realizedLPFee.toSignificant(4)} ${inputAmount.currency.symbol}`}</p>
        </div>
      )}
    </div>
  )
}

export interface AdvancedSwapDetailsProps {
  hasStablePair?: boolean
  pairs?: Pair[]
  path?: Currency[]
  priceImpactWithoutFee?: Percent
  realizedLPFee?: CurrencyAmount<Currency>
  slippageAdjustedAmounts?: {
    INPUT?: CurrencyAmount<Currency>
    OUTPUT?: CurrencyAmount<Currency>
  }
  inputAmount?: CurrencyAmount<Currency>
  outputAmount?: CurrencyAmount<Currency>
  tradeType?: TradeType
  isMM?: boolean
}

export function AdvancedSwapDetails({
  pairs,
  path,
  priceImpactWithoutFee,
  realizedLPFee,
  slippageAdjustedAmounts,
  inputAmount,
  outputAmount,
  tradeType,
  hasStablePair,
  isMM = false,
}: AdvancedSwapDetailsProps) {
  const showRoute = Boolean(path && path.length > 1)
  const token0 = path && path.length > 0 ? path[0] : undefined
  const token1 = path && path.length > 1 ? path[path.length - 1] : undefined
  return (
    <div className="flex flex-col w-full gap-1 px-4 py-2 bg-white border rounded-lg border-lime-9">
      {inputAmount && (
        <>
          <TradeSummary
            inputAmount={inputAmount}
            outputAmount={outputAmount}
            tradeType={tradeType}
            slippageAdjustedAmounts={slippageAdjustedAmounts}
            priceImpactWithoutFee={priceImpactWithoutFee}
            realizedLPFee={realizedLPFee}
            hasStablePair={hasStablePair}
            isMM={isMM}
          />
          {showRoute && (
            <>
              <div className="flex flex-row items-center justify-between">
                <p className="text-xs text-gray-6">Route</p>
                <p className="text-xs text-gray-6">{token0 && token1 ? `${token0.symbol} > ${token1.symbol}` : '-'}</p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
