import { useCallback } from 'react'
import { SmartSwapForm } from 'views/Swap/SmartSwap'
import { useSwapState } from 'state/swap/hooks'
import { Currency } from '@pancakeswap/sdk'
import useWarningImport from 'views/Swap/hooks/useWarningImport'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import currencyId from 'utils/currencyId'
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { Header } from 'components/Common/Header'
import { Field } from '../state/swap/actions'
import Bg from './_images/bg.svg'
import TitleBg from './_images/titleBg.svg'

export default function Page() {
  return (
    <div className="w-full">
      <Header />
      <div className="-mt-20">
        <Index />
      </div>
    </div>
  )
}

function Index() {
  return (
    <div className="relative isolate bg-lime-1">
      <Bg className="absolute inset-0 left-0 right-0 object-cover h-full mx-auto -z-10 w-full" />
      <div className="flex h-screen min-h-[40rem] flex-col items-center justify-center">
        <div className="hidden lg:block grow-[3]" />
        <div className="flex items-center">
          <div className="hidden lg:block text-8xl text-lime-9">
            The Open <br />
            Information <br />
            Layer
          </div>
          <div className="flex flex-col items-center lg:ml-10">
            <div className="relative flex items-center justify-center">
              <TitleBg className="absolute" />
              <div className="relative text-3xl uppercase text-lime-9">Swap</div>
            </div>

            <div className="mt-4">
              <div className="flex w-[24rem] sm:w-[32rem] mt-4 flex-col rounded-2xl border-2 border-t-8 border-lime-9 bg-lime-2 px-4 sm:px-6 py-8">
                <Form />
              </div>
            </div>
          </div>
        </div>
        <div className="grow-[4] hidden lg:block" />
      </div>
    </div>
  )
}

function Form() {
  const warningSwapHandler = useWarningImport()
  const { onCurrencySelection } = useSwapActionHandlers()

  // swap state & price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()

  const handleOutputSelect = useCallback(
    (newCurrencyOutput: Currency) => {
      onCurrencySelection(Field.OUTPUT, newCurrencyOutput)
      warningSwapHandler(newCurrencyOutput)

      const newCurrencyOutputId = currencyId(newCurrencyOutput)
      if (newCurrencyOutputId === inputCurrencyId) {
        replaceBrowserHistory('inputCurrency', outputCurrencyId)
      }
      replaceBrowserHistory('outputCurrency', newCurrencyOutputId)
    },

    [inputCurrencyId, outputCurrencyId, onCurrencySelection, warningSwapHandler],
  )

  return <SmartSwapForm handleOutputSelect={handleOutputSelect} />
}
