import Pending from "./_images/pending.svg";

export function ConfirmationPendingContent({ pendingText }: { pendingText?: string }) {
  return (
    <div className="flex flex-col items-center justify-center gap-6 min-h-44">
      <Pending />
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-lg text-black">{pendingText || "Waiting for confirmation..."}</div>
        <div className="text-xs text-gray-6"> Confirm this transaction in your wallet</div>
      </div>
    </div>
  );
}
