import { Button } from '@mantine/core'
import { useMemo, useState } from 'react'
import { Trade, TradeType, CurrencyAmount, Currency } from '@pancakeswap/sdk'
import { Field } from 'state/swap/actions'
import { basisPointsToPercent, computeTradePriceBreakdown, formatExecutionPrice, warningSeverity } from 'utils/exchange'
import { ONE_BIPS } from 'config/constants/exchange'
import { useUserSlippageTolerance } from 'state/user/hooks'

export default function SwapModalFooter({
  trade,
  isEnoughInputBalance,
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
}: {
  trade: Trade<Currency, Currency, TradeType>
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance: boolean
  onConfirm: () => void
  swapErrorMessage?: string | undefined
  disabledConfirm: boolean
}) {
  const [showInverted] = useState<boolean>(false)
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)
  const [allowedSlippage] = useUserSlippageTolerance()

  return (
    <>
      <div className="px-4 py-3 mt-3 space-y-3 bg-white border rounded-lg border-lime-9">
        <div className="flex justify-between text-xs font-medium">
          <div className="text-gray-6">Slippage Tolerance</div>
          <div>
            {typeof allowedSlippage === 'number'
              ? `${basisPointsToPercent(allowedSlippage).toFixed(2)}%`
              : allowedSlippage}
          </div>
        </div>

        <div className="flex justify-between text-xs font-medium">
          <div className="text-gray-6">Max Slippage</div>
          <div>
            {priceImpactWithoutFee
              ? priceImpactWithoutFee.lessThan(ONE_BIPS)
                ? '<0.01%'
                : `${priceImpactWithoutFee.toFixed(2)}%`
              : '-'}
          </div>
        </div>

        <div className="flex justify-between text-xs font-medium">
          <div className="text-gray-6">Service Fee</div>
          <div>{realizedLPFee ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}` : '-'}</div>
        </div>

        <div className="flex justify-between text-xs font-medium">
          <div className="text-gray-6">Price</div>
          <div>{formatExecutionPrice(trade, showInverted)}</div>
        </div>
      </div>

      <Button className="w-full mt-4 bg-lime-9" size="lg" onClick={onConfirm} disabled={disabledConfirm}>
        {severity > 2 || (trade.tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance)
          ? 'Swap Anyway'
          : 'Confirm Swap'}
      </Button>

      {swapErrorMessage ? <div className="text-xs text-left text-gray-6">{swapErrorMessage}</div> : null}
    </>
  )
}
