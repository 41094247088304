import { ReactElement, useMemo } from 'react'
import { TradeType, CurrencyAmount, Currency, Percent } from '@pancakeswap/sdk'
import { Text, AutoColumn } from '@pancakeswap/uikit'
import { Field } from 'state/swap/actions'
import { CurrencyLogo } from 'components/Logo'
import truncateHash from '@pancakeswap/utils/truncateHash'

export default function SwapModalHeader({
  inputAmount,
  outputAmount,
  tradeType,
  slippageAdjustedAmounts,
  recipient,
}: {
  inputAmount: CurrencyAmount<Currency>
  outputAmount: CurrencyAmount<Currency>
  tradeType: TradeType
  priceImpactWithoutFee?: Percent
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance: boolean
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
  allowedSlippage: number | ReactElement
}) {

  const amount =
    tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)
      : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)
  const symbol = tradeType === TradeType.EXACT_INPUT ? outputAmount.currency.symbol : inputAmount.currency.symbol

  const tradeInfoText = useMemo(() => {
    return tradeType === TradeType.EXACT_INPUT
      ? `Output is estimated. You will receive at least ${amount} ${symbol} or the transaction will revert.`
      : `Input is estimated. You will sell at most ${amount} ${symbol} or the transaction will revert.`
  }, [tradeType, amount, symbol])

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = `Output will be sent to ${truncatedRecipient}`

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="text-xs text-left text-black">{tradeInfoText}</div>
      <div className="px-4 py-3 border rounded-lg border-lime-9">
        <div className="text-sm font-medium text-lime-9">From</div>
        <div className="flex items-start justify-between mt-1">
          <div>
            <div className="font-medium">{inputAmount.toSignificant(6)}</div>
          </div>
          <div className="flex items-center">
            <CurrencyLogo currency={inputAmount.currency} size="24px" />
            <div className="ml-1.5 text-sm font-medium text-lime-9">{inputAmount.currency.symbol}</div>
          </div>
        </div>

        <div className="mt-3 text-sm font-medium text-lime-9">To</div>
        <div className="flex items-start justify-between mt-1">
          <div>
            <div className="font-medium">{outputAmount.toSignificant(6)}</div>
          </div>
          <div className="flex items-center">
            <CurrencyLogo currency={outputAmount.currency} size="24px" />
            <div className="ml-1.5 text-sm font-medium text-lime-9">{outputAmount.currency.symbol}</div>
          </div>
        </div>
      </div>

      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <Text color="textSubtle">
            {recipientSentToText}
            <b title={recipient}>{truncatedRecipient}</b>
            {postSentToText}
          </Text>
        </AutoColumn>
      ) : null}
    </div>
  )
}
